import React, { useState } from 'react';
import axios from 'axios';
import showdown from "showdown"
import ModalResponse from '../ModalResponse';

export default function ContactTestForm() {
  const [postingData, setPostingData] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    consent: 'yes'
  });
  
  const templateVariables = {
    userName: `${formData.firstName} ${formData.lastName}`,
    userEmail: formData.email,
    userPhone: formData.phone,
    userMessage: formData.message,
    userConsent: formData.consent
  };


  // = = = = = = = = 
  // Events Functions

  const displayResponseModal = () => {
    setShowResponse(true);
    setTimeout( () => setShowResponse(false), 3500)
  }

  // Add data to States
  const handleChange = e => {
    // Set Form Data
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

    // Add consent if user didn't interact with it's radio buttons
    if(formData.consent === undefined) {
      setFormData({
        ...formData,
        consent: 'yes'
      });
    }
  }

  // Handles Form Submition
  const handleSubmit = e => {
    e.preventDefault();
    let myForm = e.target;

    // Show spinner
    setPostingData(true);
    
    // Send to Serverless Function
    axios.post('/.netlify/functions/testMail', {
      userName: `${formData.firstName} ${formData.lastName}`,
      userEmail: formData.email,
      userPhone: formData.phone,
      userMessage: formData.message,
    })
    .then(response => {
      // Hide spinner
      setPostingData(false);
      // Empty Form to clean up Form Fields
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        consent: 'yes'
      });
      // Show Modal Response
      displayResponseModal();
    })
    .catch((error) => {
      setPostingData(false);
      alert(error);
    })
  }

  return (
    <>
      <form 
        onSubmit={ (e) => handleSubmit(e) } 
        className="form form--page"
        name="Contact Form"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        id="contact-form"
      >
        <input type="hidden" name="form-name" value="Contact Form" />

        <div className="form__wrapper">
          <div className="field-group">
            <div className="input-wrapper">
              <div className="input-wrapper">
                <input 
                  onChange={(e) => handleChange(e)} 
                  type="text" 
                  value={formData.firstName} 
                  name="firstName" 
                  id="first-name" 
                  placeholder="Your first Name*"
                  required
                />
                <span className={`input__custom-label ${ formData.firstName ? 'show' : '' }`}>First Name</span>
              </div>
            </div>

            <div className="input-wrapper">
                <div className="input-wrapper">
                <input 
                  onChange={(e) => handleChange(e)} 
                  type="text" 
                  value={formData.lastName} 
                  name="lastName" 
                  id="last-name" 
                  placeholder="Your last Name*"
                  required
                />
                <span className={`input__custom-label ${ formData.lastName ? 'show' : '' }`}>Last Name</span>
              </div>
            </div>
          </div>

          <div className="field-group">
            <div className="input-wrapper">
              <div className="input-wrapper">
                <input 
                onChange={(e) => handleChange(e)} 
                type="email" 
                value={formData.email} 
                name="email" 
                className="required email" 
                id="email" 
                placeholder="Your Email Address*"
                required
                />
                <span className={`input__custom-label ${ formData.email ? 'show' : '' }`}>Email</span>
              </div>
            </div>

            <div className="input-wrapper">
              <div className="input-wrapper">
                <input 
                  onChange={(e) => handleChange(e)} 
                  type="tel" 
                  value={formData.phone} 
                  name="phone" 
                  className="required phone" 
                  id="phone" 
                  placeholder="Your Mobile Phone"
                />
                <span className={`input__custom-label ${ formData.phone ? 'show' : '' }`}>Phone</span>
              </div>
            </div>
          </div>

          <div className="field-group">
            <div className="input-wrapper input-wrapper--textarea">
              <textarea 
                onChange={(e) => handleChange(e)}
                value={formData.message} 
                name="message" 
                id="message" 
                placeholder="Your Message*"
                required
                rows="5" 
              />
              <span className={`input__custom-label ${ formData.message ? 'show' : '' }`}>message</span>
            </div>
          </div>
        </div>

        <div className="form__consent">

          <div className="consent__inputs">

            <div className="radio-wrapper">
              <input 
                onChange={ (e) => handleChange(e) } 
                id="yes" 
                name="consent" 
                type="radio" 
                value="yes" 
                checked={ (formData.consent === 'yes' || formData.consent === undefined) ? true : false} 
              />
              <label htmlFor="yes">Yes</label>
            </div>

            <div className="radio-wrapper">
              <input 
                onChange={ (e) => handleChange(e) } 
                id="no" 
                name="consent" 
                type="radio" 
                value="no" 
                checked = { formData.consent === 'no' ? true : false} 
              />
              <label htmlFor="no">No</label>
            </div>
            
          </div>  
        </div>

        <div className="button-group">
          {/* <button type="submit">Submit</button> */}
          <div className="button button--submit">
            {
              postingData !== true
                ? <input readOnly type="submit" value="Submit" name="submit" id="company-submit" />
                
                : <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
            }
          </div>
        </div>          
      </form>

      {
        showResponse ? <ModalResponse /> : ''
      }
    </>
  )
}